import { resetModalModel, SET_MODAL_ADDITIONAL } from "@/store/modal/actions"
import { createParamsApi } from "../../../api"
import { fetcher } from "@/utils/fetcher"
import { getCookie, setCookie } from "@/utils/cookie"
import { checkMail } from "@/utils/inputField"
import axios from 'axios'
import { openModal } from "@/store/modal/actions"
import { setModalAdditional } from "@/store/modal/actions"
import moment from "moment"
import { getSingleStepStatus, SET_SINGLE_TRAINING_ORDER_DATA } from "@/store/user/order/actions"
import cookies from "next-cookies"

export const SET_OPTION_STEP_BY_NUMBER = 'SET_OPTION_STEP_BY_NUMBER'
export const SET_AUTH_BY_NUMBER = 'SET_AUTH_BY_NUMBER'
export const SET_USER_PAY_CARD = 'SET_USER_PAY_CARD'
export const RESET_OPTION_STEP_BY_NUMBER = 'RESET_OPTION_STEP_BY_NUMBER'
export const RESET_SIGN_IN_MODEL = 'RESET_SIGN_IN_MODEL'
export const SET_SIGN_IN_INFO = 'SET_SIGN_IN_INFO'
export const CHANGE_SIGN_IN_FETCHING = 'CHANGE_SIGN_IN_FETCHING'
export const SET_AUTH_STEP_TWO_IS_ERROR = 'SET_AUTH_STEP_TWO_IS_ERROR'
export const SET_REPEAT_SEND_CODE_TIME = 'SET_REPEAT_SEND_CODE_TIME'
export const SET_AUTH_PASSPORT_COOKIE = 'SET_AUTH_PASSPORT_COOKIE'

export const signInByNumberStepOne = ({ phone }) => async (dispatch, getState) => {
  let api = createParamsSignInByNumber({ phone })(dispatch, getState)
  let res = await fetcher(api)
  const { secret_key } = res
  const { phoneCode } = getState().user.auth.optionStepByNumber.stepsName
  dispatch({ type: SET_SIGN_IN_INFO, info: { secret_key, phone } })
  setOptionStepByNumber({ data: { step: phoneCode } })(dispatch, getState)
}

/* istanbul ignore next */
export const setPassportCookie = ({ ctx }) => (dispatch, getState) => {
  let { encData, checkSum } = cookies(ctx)
  dispatch({ type: SET_AUTH_PASSPORT_COOKIE, passport: { encData, checkSum } })
}

export const createParamsSignInByNumber = ({ phone }) => (dispatch, getState) => {
  return createParamsApi({
    method: 'signInByNumber',
    errorMessage: 'signInByNumberStepOne',
    section: 'auth',
    params: { phone }
  })
}

export const setAuthByNumber = ({ status }) => (dispatch, getState) => {
  dispatch({ type: SET_AUTH_BY_NUMBER, status })
}

export const signInByNumberStepTwo = ({ code }) => async (dispatch, getState) => {
  dispatch({ type: SET_AUTH_STEP_TWO_IS_ERROR, status: false })
  dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: true })
  if (code.length !== 6) {
    dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
    return dispatch({ type: SET_AUTH_STEP_TWO_IS_ERROR, status: true })
  }
  let api = createParamsSignInByNumberStepTwo({ code })(dispatch, getState)
  try {
    console.log(api)
    let res = await fetcher(api)
    authorize({ res })(dispatch, getState)
  } catch (e) {
    dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
    dispatch({ type: SET_AUTH_STEP_TWO_IS_ERROR, status: true })
    console.log(e)
  }
}

const authorize = ({ res }) => (dispatch, getState) => {
  const { auth_token } = res
  const promoStep = false
  setCookie({ key: 'jwt', value: auth_token })
  setAuthByNumber({ status: true })(dispatch)
  getUserInfo()(dispatch, getState)
  /* istanbul ignore next */
  if (!promoStep) afterAuthorizeMethod()(dispatch, getState)
  else showPromoModal()(dispatch, getState)
}

export const afterAuthorizeMethod = ({} = {}) => (dispatch, getState) => {
  const { optionStepByNumber } = getState().user.auth
  const { callBack } = optionStepByNumber
  if (callBack) callBack()
  else resetModalModel()(dispatch, getState)
  resetSignInModel()(dispatch, getState)
}

export const resetSignInModel = () => (dispatch, getState) => {
  dispatch({ type: RESET_OPTION_STEP_BY_NUMBER })
  dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
  dispatch({ type: SET_AUTH_STEP_TWO_IS_ERROR, status: false })
}

export const showPromoModal = () => (dispatch, getState) => {
  dispatch({ type: CHANGE_SIGN_IN_FETCHING, status: false })
  dispatch({ type: SET_AUTH_STEP_TWO_IS_ERROR, status: false })
  const { promoCode } = getState().user.auth.optionStepByNumber.stepsName
  setOptionStepByNumber({ data: { step: promoCode } })(dispatch, getState)
}

export const getPromoBonus = () => (dispatch, getState) => {
  const { promoCodeSuccess } = getState().user.auth.optionStepByNumber.stepsName
  setOptionStepByNumber({ data: { step: promoCodeSuccess } })(dispatch, getState)
}

export const createParamsSignInByNumberStepTwo = ({ code }) => (dispatch, getState) => {
  const { secret_key, phone } = getState().user.auth.signInInfo
  return createParamsApi({
    method: 'confirmPhone',
    errorMessage: 'signInByNumberStepTwo',
    section: 'auth',
    params: { code, secret_key, phone }
  })
}

export const setOptionStepByNumber = ({ data }) => (dispatch) => {
  dispatch({ type: SET_OPTION_STEP_BY_NUMBER, data })
}

export const getUserInfo = () => async (dispatch, getState) => {
  try {
    let res = await fetcher(createParamsApi({
      method: 'getInfo',
      errorMessage: 'getUserInfo',
      section: 'auth',
    }))
    if (+res.code === 401) return
    console.log(res)
    saveUserInfo({ res })(dispatch, getState)
    setAuthByNumber({ status: true })(dispatch)
    // if (typeof window !== 'undefined') alert(JSON.stringify(res))
  } catch (err) {
    console.log(err, JSON.stringify(err.response))
    return 'getUserInfo err'
  }
}

export const saveUserInfo = ({ res }) => (dispatch, getState) => {
  const { user, current_card } = res
  user.fullData = user.personal_data && user.personal_data.first_name.length > 0
  dispatch({ type: SET_USER_PAY_CARD, payCard: current_card || {} })
  dispatch({ type: SET_SIGN_IN_INFO, info: user })
  console.log('saveUserInfo')
  const { saveLocalPassport, withoutPassport } = getState().user.order.orderSingle.userData
  if (!withoutPassport) {
    if (saveLocalPassport) {
      console.log(saveLocalPassport)
      if (user.personal_data.passport.encrypted_data) {
        const { encrypted_data, check_sum } = user.personal_data.passport
        setCookie({ key: 'encData', value: encrypted_data })
        setCookie({ key: 'checkSum', value: check_sum })
        dispatch({ type: SET_AUTH_PASSPORT_COOKIE, passport: { encData: encrypted_data, checkSum: check_sum } })
      }
    }
    if (user.personal_data.passport.encrypted_data) {
      const { encrypted_data, check_sum } = user.personal_data.passport
      dispatch({ type: SET_AUTH_PASSPORT_COOKIE, passport: { encData: encrypted_data, checkSum: check_sum } })
    }
    return dispatch({
      type: SET_SINGLE_TRAINING_ORDER_DATA,
      data: { step: getSingleStepStatus()(dispatch, getState).showVoucher }
    })
  }
}

export const setUserInfo = (userInfo) => async (dispatch, getState) => {
  const { withoutPassport } = getState().user.order.orderSingle.userData
  dispatch({ type: SET_SIGN_IN_INFO, info: { fetch: true, fetchPending: true } })
  const { isInvalid } = validateUserInfoData(userInfo)
  try {
    console.log({ isInvalid }, userInfo)
    if (isInvalid) throw Error()
    const api = createSetUserInfoApi(userInfo)(dispatch, getState)
    console.log(api)
    let res = await fetcher(api)
    saveUserInfo({ res })(dispatch, getState)
    if (withoutPassport) resetModalModel()(dispatch, getState)
    dispatch({ type: SET_SIGN_IN_INFO, info: { fetch: false, fetchPending: false } })
  } catch (err) {
    console.log({ err })
    dispatch({ type: SET_SIGN_IN_INFO, info: { fetch: false } })
  }
}

export const validateUserInfoData = userInfo => {
  let isInvalid = Object.keys(userInfo).some(key => (userInfo[ key ] === undefined || userInfo[ key ] === '' || userInfo[ key ] === null))
  if (!isInvalid) isInvalid = !checkMail(userInfo.email)
  return { isInvalid }
}

export const createSetUserInfoApi = ({
                                       first_name,
                                       last_name,
                                       sex,
                                       birthday,
                                       email,
                                       number,
                                       series,
                                       date,
                                       issuedBy,
                                       needEnterPassport
                                     }) => (dispatch, getState) => {
  let api = createParamsApi({ method: 'setInfo', section: 'auth', })
  console.log(date)
  api.params = {
    personal_data: {
      first_name,
      last_name,
      sex: sex === 1 ? 'female' : 'male',
      birthday: typeof birthday === 'string' ? birthday : moment(birthday).format('DD.MM.YYYY'),
      email
    }
  }
  if (needEnterPassport) {
    api.params.personal_data = {
      ...api.params.personal_data,
      passport: {
        number: series + ' ' + number,
        date: moment(JSON.parse(JSON.stringify(date))).format('DD.MM.YYYY'),
        issued_by: issuedBy
      }
    }
  }
  return api
}

export const logOut = (router) => async (dispatch, getState) => {
  let res = await fetcher(createParamsApi({
    method: 'logOut',
    errorMessage: 'logOut',
    section: 'auth',
  }))
  setCookie({ key: 'jwt', value: '' })
  setAuthByNumber({ status: false })(dispatch)
  router.push('/')
  dispatch({ type: RESET_SIGN_IN_MODEL })
}
/* istanbul ignore next */
export const repeatSendCode = (sec) => async (dispatch, getState) => {
  let { repeatSendCodeTime } = getState().user.auth
  if (!repeatSendCodeTime) {
    if (sec) {
      repeatSendCodeTime = 60
      let { phone } = getState().user.auth.signInInfo
      await signInByNumberStepOne({ phone })(dispatch, getState)
    }
    else return
  }
  let time = repeatSendCodeTime === 1 ? null : +repeatSendCodeTime - 1
  dispatch({ type: SET_REPEAT_SEND_CODE_TIME, time })
  setTimeout(() => {
    repeatSendCode()(dispatch, getState)
  }, 1000)
}

export const removePayCard = () => async (dispatch, getState) => {
  const { payCard } = getState().user.auth
  dispatch({ type: SET_USER_PAY_CARD, payCard: { ...payCard, fetch: true } })
  let api = createParamsApi({ method: 'removeCurrentCard', section: 'auth', errorMessage: 'removePayCard' })
  let res = await fetcher(api)
  await getUserInfo()(dispatch, getState)
  resetModalModel()(dispatch, getState)
}

export const checkIsAuth = ({ router, res }) => (dispatch, getState) => {
  // const { isAuth } = getState().user.auth
  const { isAuth } = false
  if (!isAuth) {
    const { modalNames } = getState().modal
    if (res) {
      res.writeHead(301, {
        Location: '/?auth=true',
        'Content-Type': 'text/html; charset=utf-8',
      })
      res.end()
    }
    else if (router) {
      router.push('/')
      setModalAdditional({ className: 'autoContainer' })(dispatch, getState)
      openModal(modalNames.authByNumber)(dispatch, getState)
    }
  }
  return isAuth
}
